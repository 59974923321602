import { App } from 'vue'
import { createWebHistory, createRouter, RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        redirect: '/en'
    },
    {
        path: '/:lang',
        name: 'Home',
        component: () => import(/* webpackChunkName: "other" */ '../views/Home.vue')
    },
    {
        path: '/:lang/house/:city/:street/:number/:id',
        name: 'House',
        component: () => import(/* webpackChunkName: "house" */ '../views/House.vue')
    },
    {
        path: '/:lang/explore/:city',
        name: 'Explore',
        component: () => import(/* webpackChunkName: "explore" */ '../views/Explore.vue')
    },
    {
        path: '/:lang/search/:city',
        name: 'Search',
        component: () => import(/* webpackChunkName: "others" */ '../views/Search.vue')
    },
    {
        path: '/:lang/terms',
        name: 'Terms',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "others" */ '../views/Terms.vue')
    },
    {
        path: '/:lang/privacy',
        name: 'Privacy',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "others" */ '../views/Privacy.vue')
    }
]

function registerRouter (app : App<Element>) {
    const router = createRouter({
        // Provide the history implementation to use. We are using the hash history for simplicity here.
        history: createWebHistory(),
        routes // short for `routes: routes`
        // base: process.env.BASE_URL,
    })
    app.use(router)
    return router
}

export { registerRouter }
