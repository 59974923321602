import { App } from 'vue'
import { createI18n } from 'vue-i18n'
import { messages, defaultLocale } from '@/translation/config'

function registerI18n (app : App<Element>) {
    const i18n = createI18n({
        legacy: false,
        messages,
        locale: defaultLocale,
        fallbackLocale: defaultLocale
    })
    app.use(i18n)
    return i18n
}

export { registerI18n }
