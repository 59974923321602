import { createApp } from 'vue'
import App from './App.vue'
import { registerRouter } from './plugins/router'
import { registerBootstrap } from './plugins/bootstrap-vue'
import { registerFontAwesome } from './plugins/fontAwesome'
import { registerI18n } from './plugins/i18n'
import { registerMeta } from './plugins/meta'
import { registerGtag } from './plugins/gtag'
// Vue.config.productionTip = false
import ScriptX from 'vue-scriptx'
import Ads from 'vue-google-adsense'


const app = createApp(App)
app.use(ScriptX)
app.use(Ads.Adsense, { adClient: 'ca-pub-9057030504180501', isNewAdsCode: true })
app.use(Ads.InFeedAdsense, { adClient: 'ca-pub-9057030504180501', isNewAdsCode: true })

const router = registerRouter(app)
registerBootstrap(app)
registerFontAwesome(app)
registerI18n(app)
registerMeta(app)
registerGtag(app, router)
router.isReady().then(() => app.mount('#app'))
// app.mount('#app')
// router.isReady().then(() => app.mixin(Title))
