import { library } from '@fortawesome/fontawesome-svg-core'
import { faBarsProgress, faUpRightFromSquare, faAt, faClone, faBarcode, faLocationDot, faCircleCheck, faDoorOpen, faChartLine, faStar, faCartShopping, faUserGroup, faClipboardList, faAward, faMapMarkerAlt, faBed, faBuilding, faHelicopter, faHouseChimneyUser, faMagnifyingGlass, faMap, faCircleXmark, faCreditCard, faRulerCombined, faBolt, faCircleInfo, faRobot, faCalendarDays, faMagnifyingGlassChart } from '@fortawesome/free-solid-svg-icons'
import { faAndroid, faFacebook } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { App } from 'vue'

function registerFontAwesome (app : App<Element>) {
    library.add(faMapMarkerAlt)
    library.add(faBarsProgress)
    library.add(faBed)
    library.add(faBuilding)
    library.add(faHelicopter)
    library.add(faHouseChimneyUser)
    library.add(faMagnifyingGlass)
    library.add(faMap)
    library.add(faCircleXmark)
    library.add(faCreditCard)
    library.add(faRulerCombined)
    library.add(faBolt)
    library.add(faCircleInfo)
    library.add(faAward)
    library.add(faClipboardList)
    library.add(faUserGroup)
    library.add(faCartShopping)
    library.add(faStar)
    library.add(faChartLine)
    library.add(faDoorOpen)
    library.add(faCircleCheck)
    library.add(faRobot)
    library.add(faCalendarDays)
    library.add(faLocationDot)
    library.add(faBarcode)
    library.add(faClone)
    library.add(faAndroid)
    library.add(faFacebook)
    library.add(faAt)
    library.add(faUpRightFromSquare)
    library.add(faMagnifyingGlassChart)
    app.component('font-awesome-icon', FontAwesomeIcon)
}

export { registerFontAwesome }
