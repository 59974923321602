import { App } from 'vue'
import {
    createMetaManager,
    defaultConfig,
  } from 'vue-meta'

function registerMeta (app : App<Element>) {
    app.use(createMetaManager(false, {
        ...defaultConfig,
        meta: { tag: 'meta', nameless: true },
      }))
}

export { registerMeta }
