import { BootstrapVue3 } from 'bootstrap-vue-3'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import { App } from 'vue'

function registerBootstrap (app : App<Element>) {
    app.use(BootstrapVue3)
}

export { registerBootstrap }
