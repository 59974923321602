import { App } from 'vue'
import VueGtag from 'vue-gtag'
import { Router } from 'vue-router'

function registerGtag (app : App<Element>, router : Router) {
    app.use(VueGtag, {
        config: { id: 'G-M7D6ZFZN2T', params: { anonymize_ip: true } },
        enabled: true
    }, router)
}

export { registerGtag }
