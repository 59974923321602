<template>
  <metainfo></metainfo>
  <div id="app">
      <router-view/>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import { Metadata } from '@/model/Metadata'
import { useMeta } from 'vue-meta'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
    setup () {
        const route = useRoute()
        const router = useRouter()
        const metadata = new Metadata()
        const title = 'HuisKans'
        const otherLang = computed(() => {
            return route.params.lang === 'en' ? 'nl' : 'en'
        })

        const canonicalUrl = computed(() => {
            const params = { ...router.currentRoute.value.params, lang: otherLang.value }
            const { protocol, host } = window.location
            const baseURL = `${protocol}//${host}`
            const path = router.resolve({ name: route.name as string, params }).href
            return `${baseURL}${path}`
        })

        useMeta({
            title: title,
            link: [
                {
                    rel: 'alternate',
                    hreflang: otherLang.value,
                    href: canonicalUrl.value
                }
            ],
            meta: [
                {
                    property: 'og:title',
                    content: title,
                    vmid: 'og:title'
                },
                {
                    property: 'application-name',
                    content: metadata.getApplicationName(),
                    vmid: 'application-name'
                },
                {
                    property: 'theme-color',
                    content: metadata.getTheme(),
                    vmid: 'theme-color'
                },
                {
                    property: 'og:site_name',
                    content: metadata.getApplicationName(),
                    vmid: 'og:site_name'
                },
                {
                    property: 'apple-mobile-web-app-title',
                    content: title,
                    vmid: 'apple-mobile-web-app-title'
                },
                {
                    property: 'og:url',
                    content: metadata.getUrl(),
                    vmid: 'og:url'
                },
                {
                    property: 'og:type',
                    content: metadata.getType(),
                    vmid: 'og:type'
                },
                {
                    property: 'url',
                    content: metadata.getUrl(),
                    vmid: 'url'
                },
                {
                    property: 'name',
                    content: title,
                    vmid: 'name'
                }
            ]
        })
    }
})
</script>
