import en from '@/translation/en.json'
import nl from '@/translation/nl.json'

export enum Locales {
    EN = 'en',
    NL = 'nl',
}
export const LOCALES = [
    { value: Locales.EN, caption: 'English' },
    { value: Locales.NL, caption: 'Nederlands' }
]
export const messages = {
    [Locales.EN]: en,
    [Locales.NL]: nl
}
export const defaultLocale = Locales.EN
