import { useRoute } from 'vue-router'
import { SingleResult } from './SingleResult'

export class Metadata {
    public route = useRoute()
    public charset = 'utf-8'
    public viewport = 'width=device-width,initial-scale=1.0'
    public applicationName = 'HuisKans'
    public themeColor = '#FFFFFF'
    public imageWidth = '145'
    public imageHeight = '145'
    public type = 'article'
    public suffix = ' - HuisKans'

    getPropertyTitle (result: SingleResult): string {
        const houseNumbers = [result.houseInfo?.houseNumber]
        if (result.houseInfo?.houseNumberAddition) {
            houseNumbers.push(result.houseInfo.houseNumberAddition)
        }
        const streetName = this.ucFirst(result.houseInfo?.streetName || '')
        const stHouseNumber = this.ucFirst(houseNumbers.join('-'))
        const city = this.ucFirst(result.houseInfo?.city || '')
        return streetName + ' ' + stHouseNumber + ', ' + city + this.suffix
    }

    getHomeTitle (t: (text: string) => string): string {
        return t('home.title') + this.suffix
    }

    getTermsTitle (): string {
        return 'Algemene Voorwaarden' + this.suffix
    }

    getPrivacyTitle (): string {
        return 'Privacyverklaring' + this.suffix
    }

    getSearchTitle (city: string, t: (text: string) => string): string {
        return t('search.title') + ' ' + this.ucFirst(city) + this.suffix
    }

    getExploreTitle (city: string, t: (text: string) => string): string {
        return t('explore.title') + ' ' + this.ucFirst(city) + this.suffix
    }

    getPropertyImage (result: SingleResult): string {
        return result.getPhoto()
    }

    getPropertyDescription (result: SingleResult, t: (text: string) => string): string {
        return result.getDescription(t, false)
    }

    getUrl (): string {
        return location.href
    }

    getType (): string {
        return this.type
    }

    getCharset (): string {
        return this.charset
    }

    getApplicationName (): string {
        return this.applicationName
    }

    getViewport (): string {
        return this.viewport
    }

    getImageWidth (): string {
        return this.imageWidth
    }

    getImageHeight (): string {
        return this.imageHeight
    }

    getTheme (): string {
        return this.themeColor
    }

    getCity (): string {
        if (this.route === undefined) {
            this.route = useRoute()
        }
        const city = this.route.params.city
        return Array.isArray(city) ? city[0] : city
    }

    getStreet (): string {
        if (this.route === undefined) {
            this.route = useRoute()
        }
        const street = this.route.params.street
        return Array.isArray(street) ? street[0] : street
    }

    getNumber (): string {
        if (this.route === undefined) {
            this.route = useRoute()
        }
        const number = this.route.params.number
        return Array.isArray(number) ? number[0] : number
    }

    getName (): string {
        if (this.route === undefined) {
            this.route = useRoute()
        }
        const name = this.route.name
        return Array.isArray(name) ? name[0] : name
    }

    ucFirst (inp: string): string {
        return inp.charAt(0).toUpperCase() + inp.slice(1)
    }
}
